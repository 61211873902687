/*
Template Name: T-O - Admin & Dashboard Template
Author: Digitalkrapht
Version: 3.0.0
Website: https://digitalkrapht.com/
Contact: admin@digitalkrapht.com
File: Icons Css File
*/

// Plugins
@import "custom/plugins/icons";